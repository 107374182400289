import * as utilities from "../../utilities";

let purchase = function ( ddo ) {

	// Cross-Account Conversion - Name: "Sale" - Category: Purchase/Sale

	let fn = function ( o ) {

		console.log( "fn called with o: ", o );

		return function () {
			window.google_trackConversion( o )
		};

	}( {
		"google_conversion_id": 985322823,
		"google_conversion_language": 'en',
		"google_conversion_format": '3',
		"google_conversion_color": 'ffffff',
		"google_conversion_label": "AWW_CPH-5AgQx6rr1QM",
		"google_conversion_value": ( ddo.transaction.price.transactionTotal ? ddo.transaction.price.transactionTotal : '' ),
		"google_conversion_currency": "USD",
		"google_remarketing_only": false,
	} );

	"function" != typeof window.google_trackConversion ? utilities.insertScriptWithCallback( "//www.googleadservices.com/pagead/conversion_async.js",

		function ( a ) {
			console.log( "hello a: ", a );
			return a
		}( fn ) ) : fn();

};

export {
	purchase,
};

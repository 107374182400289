//fires on the start of the services form
let start = function ( ddo ) {
	let pageName = ( typeof ddo.page.pageInfo.pageName === 'string' ? ddo.page.pageInfo.pageName.toLowerCase() : "" );

	new Image().src = '//ad.doubleclick.net/ddm/activity/src=3518820;type=homes971;cat=homes005;u1=' + pageName;

};

//fires on the completion of the services form
let purchase = function () {

	new Image().src = '//ad.doubleclick.net/ddm/activity/src=3518820;type=hs_sls0;cat=001;qty=1'

};

export {
	start,
	purchase
}

import * as utilities from "../../utilities";

let dblClkLastExecuted = "";

let tagRequest = function ( srcMetrics ) {
	utilities.insertScript( srcMetrics );
};

let run = function ( ddo ) {
	let now = new Date().getTime(),
		threshold = 1200,
		elapsed = ( dblClkLastExecuted ? now - dblClkLastExecuted : -1 );

	// abort calls within 'threshold' milliseconds of each other
	if ( elapsed !== -1 && elapsed < threshold ) {
		return;
	}

	_aape.dblClkSent = true;

	let scriptSrc;

	//On All Pages:
	//Set u1 = modified page title
	//Set u5 = product ID/sku when product page

	//To prevent caching beacon request:
	//For standard Floodlight tags, use a random number.
	//For unique user tags, use a constant value. (Requires random Num value set)
	//For sales tags, use an order confirmation number.
	let qry = {},
		rnd = Math.random() * 10000000000000,
		transaction = ddo.transaction,
		pageName = ( typeof ddo.page.pageInfo.pageName === 'string' ? ddo.page.pageInfo.pageName.toLowerCase() : "" ),
		pageType = ( typeof ddo.page.pageInfo.pageType === 'string' ? ddo.page.pageInfo.pageType : "" );


	/* Set srcMetrics On Order Confirmation Page */


	if ( pageName === "store finder" ) {
		qry = {
			src: '3518820',
			type: 'thd',
			cat: 'storfind'
		};

		if ( ddo.user.profile.profileInfo.profileID ) {
			qry.ord = ddo.user.profile.profileInfo.profileID;
			qry.num = rnd;
		}
		else if ( transaction.transactionID ) {
			qry.ord = transaction.transactionID;
			qry.num = rnd;
		}
		else if ( ddo.legacy && ddo.legacy.transactionID ) {
			qry.ord = ddo.legacy.transactionID;
			qry.num = rnd;
		}
		else if ( _aape.ECID ) {
			qry.ord = _aape.ECID;
			qry.num = rnd;
		}
		else {
			qry.ord = rnd;
		}

		scriptSrc = document.location.protocol + '//fls.doubleclick.net/activityj' +
			';src=' + qry.src +
			';type=' + qry.type +
			';cat=' + qry.cat +
			';u16=' + qry.ord +
			';u26=' + encodeURIComponent(document.URL);

	}
	else {
		/* If not order confirmation page, set srcMetrics for all other pages */
		
		/*checks to see if curent page is design center otherwise defaults to catchall category. */
		let getCat = function () {
			if ( document.URL.indexOf( 'hddc' ) > -1 || pageName.indexOf( 'design center' ) > -1 ) {
				return 'hddc01';
			}
			else {
				return 'thd001';
			}
		};

		qry = {
			src: '3518820',
			type: 'thd',
			cat: getCat()
		};
		let dependentVars = ( qry.skus ) ? ';u5=' + qry.skus : '',
			productId = ( Array.isArray( ddo.product ) && ddo.product[ 0 ] && ddo.product[ 0 ].productInfo && ddo.product[ 0 ].productInfo.sku ? ddo.product[ 0 ].productInfo.sku : "" );

		dependentVars += ( qry.num ) ? ';num=' + qry.num : '';

		qry.title = ( document.title.indexOf( ' at The Home Depot' ) > 0 ) ? document.title.substr( 0, document.title.indexOf( ' at The Home Depot' ) ) : document.title;

		if ( pageType === 'pip' && productId ) {
			qry.prodID = productId;
		}

		if ( ddo.user.profile.profileInfo.profileID ) {
			qry.ord = ddo.user.profile.profileInfo.profileID;
			qry.num = rnd;
		}
		else if ( transaction.transactionID ) {
			qry.ord = transaction.transactionID;
			qry.num = rnd;
		}
		else if ( ddo.legacy && ddo.legacy.transactionID ) {
			qry.ord = ddo.legacy.transactionID;
			qry.num = rnd;
		}
		else if ( _aape.ECID ) {
			qry.ord = ( _aape.ECID );
			qry.num = rnd;
		}
		else {
			qry.ord = rnd;
		}

		scriptSrc = document.location.protocol + '//fls.doubleclick.net/activityj' +
			';src=' + qry.src +
			';type=' + qry.type +
			';cat=' + qry.cat +
			';u16=' + qry.ord +
			';u26=' + encodeURIComponent(document.URL) +
			';u5=' + ( pageType === 'pip' ? productId : '' ) +
			';u1=' + qry.title +
			dependentVars + '?';
	}

	tagRequest( scriptSrc );


	dblClkLastExecuted = new Date().getTime();

};


let purchase = function ( ddo ) {

	let qry = {},
	rnd = Math.random() * 10000000000000,
	scriptSrc = "";

	let getTransactionData = function () {
		let result = {
				'productids': [],
				'units': [],
				'prices': [],
				'totalUnits': 0,
				'totalRevenue': 0
			},
			dti = ddo.transaction.item,
			itemArray = ( dti && dti.length ? dti : [] ),
			i = 0;

		for ( i = 0; i < itemArray.length; i++ ) {
			let pid = ( itemArray[ i ].productInfo && itemArray[ i ].productInfo.sku ? itemArray[ i ].productInfo.sku : '' ),
				qty = ( itemArray[ i ].quantity ? itemArray[ i ].quantity : 0 ),
				price = ( itemArray[ i ].price && itemArray[ i ].price.basePrice ? itemArray[ i ].price.basePrice : 0 );

			result.productids.push( pid );
			result.units.push( qty );
			result.prices.push( price );
			result.totalUnits += parseInt( qty );
			result.totalRevenue += parseInt( price );
		}
		return result;
	};

	let transactionData = getTransactionData( ddo ),
		hasTransactionData = transactionData[ 'productids' ].length > 0,
		totalUnits = ( hasTransactionData ? transactionData[ 'totalUnits' ] : '' ),
		totalRevenue = ( hasTransactionData ? transactionData[ 'totalRevenue' ] : '' ),
		listOfRevenues = ( hasTransactionData ? transactionData[ 'prices' ].join( "|" ) : '' ),
		listOfUnitCounts = ( hasTransactionData ? transactionData[ 'units' ].join( "|" ) : '' ),
		listOfIDs = ( hasTransactionData ? transactionData[ 'productids' ].join( "|" ) : '' );

	let dblclkGF = function () {
		let res = 'N';
		if ( ddo.cart && ddo.cart.price ) {
			res = ( ddo.cart.price.paymentMethod === "gift cart" ? 'Y' : 'N' );
		}
		return res;
	};

	qry = {
		src: '3518820',
		type: 'hdsales',
		cat: 'saleconf',
		qty: totalUnits, //total units - entire order
		cost: totalRevenue, //total revenue - entire order
		promo: ( ddo.transaction.price.voucherCode ? ddo.transaction.price.voucherCode : '' ), //any special/promo code used (.promo??)
		taxes: ( ddo.transaction.price.tax ? ddo.transaction.price.tax : '' ), //sales tax
		shipping: ( ddo.transaction.price.shipping ? ddo.transaction.price.shipping : '' ), //Shipping Cost
		discounts: ( ddo.transaction.price.voucherDiscount ? ddo.transaction.price.voucherDiscount : '' ), //Credits / Discounts Amount (.discount??)
		revenues: listOfRevenues, //Revenue Per SKU
		productsOrdered: ( utilities.getValuesFromObj( 'productInfo.productName', ddo.transaction.item ) ? utilities.getValuesFromObj( 'productInfo.productName', ddo.transaction.item ) : '' ),
		unitCounts: listOfUnitCounts, //Units/Quantity Per SKU
		skus: listOfIDs, //Product SKU IDs
		zip: ( ddo.transaction.profile.address.postalCode ? ddo.transaction.profile.address.postalCode : '' ), //Zip Code
		state: ( ddo.transaction.profile.address.stateProvince ? ddo.transaction.profile.address.stateProvince : '' ), //Stated
		city: ( ddo.transaction.profile.address.city ? ddo.transaction.profile.address.city : '' ), //City
		adobeUserId: ( _aape.ECID ), //Customer ID from Tron
		isGiftCard: dblclkGF()
	};

	if ( ddo.transaction.transactionID ) {
		qry.ord = ddo.transaction.transactionID;
	}
	else if ( ddo.user.profile.profileInfo.profileID ) {
		qry.ord = ddo.user.profile.profileInfo.profileID;
	}
	else if ( ddo.legacy && ddo.legacy.transactionID ) {
		qry.ord = ddo.legacy.transactionID;
	}
	else if ( _aape.ECID ) {
		qry.ord = ( _aape.ECID );
	}
	else {
		qry.ord = rnd;
	}

	scriptSrc = document.location.protocol + '//fls.doubleclick.net/activityj' +
		';src=' + qry.src +
		';type=' + qry.type +
		';cat=' + qry.cat +
		';cost=' + qry.cost +
		';u26=' + encodeURIComponent(document.URL) +
		';u20=' + qry.qty +
		';u17=' + qry.productsOrdered +
		';u19=' + qry.isGiftCard +
		';u16=' + qry.adobeUserId +
		';u11=' + qry.promo +
		';u10=' + qry.taxes +
		';u9=' + qry.shipping +
		';u8=' + qry.discounts +
		';u7=' + qry.revenues +
		';u6=' + qry.unitCounts +
		';u5=' + qry.skus +
		';u4=' + qry.zip +
		';u3=' + qry.state +
		';u2=' + qry.city +
		';ord=' + qry.ord;

	scriptSrc += '?';

	tagRequest( scriptSrc );
};

export {
	run,
	purchase
}

import * as utilities from "./utilities";
const remoteUtils = await import( 'AAPE_Sync/utilities' );

let shouldIExecute = function ( excludedpaths, currentpath ) {

	excludedpaths = ( typeof ( excludedpaths ) !== 'undefined' ? excludedpaths : [] );
	currentpath = ( typeof ( currentpath ) === 'string' ? currentpath : window.location.pathname );

	for ( let index = 0; index < excludedpaths.length; index++ ) {

		if ( typeof ( currentpath ) === 'string' && currentpath.indexOf( excludedpaths[ index ] ) !== -1 ) {
			return false;
		}
	}

	return true;
};

//ccpa logic to check for which third parties can fire.
let optOutGroup2 = false,
	optOutGroup3 = false,
	optOutGroup4 = false,
	optOutGroup5 = false,
	optCookie = remoteUtils.readCookie( 'OptOutOnRequest' );

if ( optCookie ) {

	( function () {
		let results = {},
			groups = optCookie.split( "groups=" );

		if ( Array.isArray( groups ) && groups.length > 0 ) {

			results = groups[ 1 ].split( ',' );

			if ( Array.isArray( results ) && results.length > 0 ) {

				//expected format of results is ["C0001:0", "C0002:0", "C0003:0", "C0004:1", "C0005:0"]
				results.forEach( function ( group ) {

					group = group.split( ":" );

					if ( Array.isArray( group ) && group.length > 1 && group[ 0 ] === "C0004" ) {
						optOutGroup4 = ( group[ 1 ] === "1" ? true : false );
					}

				} );
			}
		}
		return results;
	}() );

}

//Global Privacy Control Check
let globalPrivCheck = ( navigator.globalPrivacyControl && navigator.globalPrivacyControl === true ? true : false );

// ALIASES for paths to exclude
let OPC = "/mycheckout/checkout";

let togglesSet = false;
let toggleSwitches = {};

const isATCImpressionsEnabled = () => {  	
    return ( window.parent.location.pathname.indexOf("/p/") !== -1 && ["/mycart/overlay"].includes(window.location.pathname) );        
};

if ( BUILD_TARGET === "b2consumer-mobile" ) {
	toggleSwitches = {		
		"atcimpressions": {
			"enabled": isATCImpressionsEnabled()
		},
		"mbox": {
			"enabled": ( !optOutGroup5 )
		},
		"metarouter": {
			"enabled": ( shouldIExecute( [ OPC ] ) )
		},
		"bluetriangle": {
			"enabled": ( !optOutGroup3 )
		},
		"doubleclick": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"doubleclickhddc": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},	
		"doubleclickservices": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"forter": {
			"enabled": ( !optOutGroup2 )
		},
		"googleadwords": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"qualtrics": {
			"enabled": ( !optOutGroup5 )
		},
		"quantummetric": {
			"enabled": ( !optOutGroup3 )
		},
		"sprinklr": {
			"enabled": ( !optOutGroup2 && !remoteUtils.isPIP())
		},
	}
}

if ( BUILD_TARGET === "b2business-mobile" ) {
	toggleSwitches = {
		"atcimpressions": {
			"enabled": isATCImpressionsEnabled()
		},
		"mbox": {
			"enabled": ( !optOutGroup5 )
		},
		"metarouter": {
			"enabled": ( shouldIExecute( [ OPC ] ) )
		},
		"bluetriangle": {
			"enabled": ( !optOutGroup3 )
		},
		"doubleclick": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"doubleclickhddc": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"doubleclickservices": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"forter": {
			"enabled": ( optOutGroup2 )
		},
		"googleadwords": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"qualtrics": {
			"enabled": ( !optOutGroup5 )
		},
		"quantummetric": {
			"enabled": ( !optOutGroup3 )
		},
		"sprinklr": {
			"enabled": ( !optOutGroup2 && !remoteUtils.isPIP() )
		},
	}
}

if ( BUILD_TARGET === "b2consumer-desktop" ) {
	toggleSwitches = {
		"atcimpressions": {
			"enabled": isATCImpressionsEnabled()
		},
		"mbox": {
			"enabled": ( !optOutGroup5 )
		},
		"metarouter": {
			"enabled": ( shouldIExecute( [ OPC ] ) )
		},
		"bluetriangle": {
			"enabled": ( !optOutGroup3 )
		},
		"doubleclick": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"doubleclickhddc": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"doubleclickservices": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"forter": {
			"enabled": ( !optOutGroup2 )
		},
		"googleadwords": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"qualtrics": {
			"enabled": ( !optOutGroup5 )
		},
		"quantummetric": {
			"enabled": ( !optOutGroup3 )
		},
		"sprinklr": {
			"enabled": ( !optOutGroup2 )
		}
	}
}

if ( BUILD_TARGET === "b2business-desktop" ) {
	toggleSwitches = {
		"atcimpressions": {
			"enabled": isATCImpressionsEnabled()
		},
		"mbox": {
			"enabled": ( !optOutGroup5 )
		},
		"metarouter": {
			"enabled": ( shouldIExecute( [ OPC ] ) )
		},
		"bluetriangle": {
			"enabled": ( !optOutGroup3 )
		},
		"doubleclick": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"doubleclickhddc": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"doubleclickservices": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"forter": {
			"enabled": ( !optOutGroup2 )
		},
		"googleadwords": {
			"enabled": ( shouldIExecute( [ OPC ] ) && !optOutGroup4 && !globalPrivCheck )
		},
		"qualtrics": {
			"enabled": ( !optOutGroup5 )
		},
		"quantummetric": {
			"enabled": ( !optOutGroup3 )
		},
		"sprinklr": {
			"enabled": ( !optOutGroup2 )
		},
	}
}

/**
 * reads the toggles and sets the feature accordingly.
 */
let setUpToggles = function () {

	/**
	 * Sets the throttle based on configuration
	 * @param {number} throttle
	 * @returns {boolean}
	 */
	//wrap in function whether true or false
	let checkForThrottle = function ( throttle = 0, key = "unknown" ) {

		let rand = parseInt( ( Math.random() * 100 ) );

		if ( rand < throttle ) {

			console.log( "Throttle true" );
			utilities.write( key, "true" );

			return true;

		}
		else {

			console.log( "Throttle false" );
			utilities.write( key, "false" );

			return false;

		}

	};

	let throttle = function ( objToggles ) {
		//iterate through object. if feature has throttle defined, check cookie and override enabled if set.

		for ( let switchNode in objToggles ) {

			if ( objToggles.hasOwnProperty( switchNode ) ) {

				let featureThrottle = objToggles[ switchNode ].throttle;

				if ( featureThrottle ) {

					//check if already set in cookie
					let readThrottle = utilities.read( switchNode );

					if ( readThrottle ) {

						if ( readThrottle === "true" ) {
							objToggles[ switchNode ].enabled = true;
						}
						else {
							objToggles[ switchNode ].enabled = false;
						}

					}
					else {
						objToggles[ switchNode ].enabled = checkForThrottle( featureThrottle, switchNode );
					}
				}
			}
		}

		togglesSet = true;

	};

	throttle( toggleSwitches );

};

/**
 * Check if feature enabled
 * @param {string} featureName
 * @param {object} [toggles] -json object
 * @returns {boolean}
 */
let featureEnabled = function ( featureName, toggles ) {

	if ( !togglesSet ) {
		setUpToggles();
	}

	toggles = toggles ? toggles : toggleSwitches; //todo: enforce camel case naming convention.

	for ( let feature in toggles ) {
		if ( toggles.hasOwnProperty( feature ) ) {
			if ( featureName === feature ) {
				return toggles[ feature ].enabled;
			}
		}
	}
	return false;
};

export {
	featureEnabled
};
